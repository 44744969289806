<template>
  <div class="faq-details py-8">
    <DocumentAppBar />

    <div class="ma-4">
      <ViewingTitle :title="displayName" />
      <DeleteOrRestore
        v-if="hasDeletePermission"
        :model="folder"
        name="Document"
        redirect="/hub/documents"
      />

      <div class="d-flex mb-6">
        <v-tabs hide-slider class="faq-info-tabs">
          <v-tab class="pa-0" v-for="(item, key) in tabItems" :key="key">
            {{ item.tab }}
          </v-tab>

          <v-tab-item class="col-sm-12 col-xs-12 mt-6 pa-0">
            <v-divider class="faq-details__divider"></v-divider>

            <DocumentForm :folder="folder" :access-building="accessBuilding" />
          </v-tab-item>
        </v-tabs>
      </div>
    </div>

    <Loading :value="loading" />
  </div>
</template>

<script>
/**
 * ==================================================================================
 * View Hub FAQ
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import ViewingTitle from '@/components/common/ViewingTitle'
import DocumentForm from '@/views/Home/Hub/Documents/components/DocumentForm'
import DocumentAppBar from '@/views/Home/Hub/Documents/components/DocumentAppBar'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import { access } from '@/utils/accessBuilding'

export default {
  name: 'DocumetDetails',

  components: {
    ViewingTitle,
    DocumentAppBar,
    DocumentForm,
    Loading,
    DeleteOrRestore,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
      tabItems: [{ tab: 'Info' }],
    }
  },

  computed: {
    ...mapState({
      folder: (state) => state.folder.folderDetails,
      permissions: (state) => state.auth.permissions,
      buildingList: (state) => state.filter.buildingList,
    }),

    displayName() {
      return this.folder ? this.folder.name : null
    },

    hasDeletePermission() {
      return (
        validatePermissions([PERMISSION.DOCUMENTS_DELETE], this.permissions) &&
        this.accessBuilding
      )
    },

    accessBuilding() {
      return access(this.folder, this.buildingList)
    },
  },

  methods: {
    ...mapActions({
      getFolderDetails: 'folder/getFolderDetails',
    }),

    ...mapMutations({
      clearFolderDetails: 'folder/clearFolderDetails',
    }),

    async getFolder() {
      this.loading = true
      await this.getFolderDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getFolder()
  },

  destroyed() {
    this.clearFolderDetails()
  },

  watch: {
    $route() {
      this.getFolder()
    },
  },
}
</script>

<style lang="scss" scoped>
.faq-details {
  &__divider {
    max-width: 400px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
